import { Container } from "@chakra-ui/react";
import React from "react";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";

interface IPageContainer {
  children: React.ReactNode;
}

export const PageContainer: React.FC<IPageContainer> = ({ children }) => {
  return (
    <>
      <Navbar />
      <Container maxW="6xl">{children}</Container>
      <Footer />
    </>
  );
};
