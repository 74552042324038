import { Box, Divider, Flex, Text, Icon } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import React from "react";

interface IMentorFacts {
  facts: {
    firstFact: string | null;
    secondFact: string | null;
    thirdFact: string | null;
    fourthFact: string | null;
  };
}

export const MentorFacts: React.FC<IMentorFacts> = ({ facts }) => {
  const factsList = [
    facts.firstFact,
    facts.secondFact,
    facts.thirdFact,
    facts.fourthFact,
  ];
  return (
    <Box my={8}>
      <Divider />
      <Box py={8}>
        {factsList.map((fact, index) => {
          if (!fact || fact.length === 0) {
            return;
          }
          return <MentorFactItem key={index} text={fact} />;
        })}
      </Box>
      <Divider />
    </Box>
  );
};

interface IMentorFactItem {
  text: string;
}

const MentorFactItem: React.FC<IMentorFactItem> = ({ text }) => (
  <Flex my={2} alignItems={"center"} justifyContent={"flex-start"}>
    <Icon color={"primaryColor"} w={2} h={2} mr={6} as={FaCircle} />
    <Text fontWeight={"semibold"}>{text}</Text>
  </Flex>
);
