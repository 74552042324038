import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components, MultiValue, SingleValue, Options } from "react-select";

export interface Option {
  readonly label: string;
  readonly value: string;
}

interface ICreatableMultiSelect {
  name: string;
  formValue: any;
  isMulti?: boolean;
  setFieldValue: (
    field: string,
    selectedOptions: MultiValue<Option> | SingleValue<Option>
  ) => void;
  allOptions: Option[];
  onCreateNewOption: (collectionName: string, option: Option) => Promise<void>;
  onSetCreateNewOptionError: (msg: string) => void;
}

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase(),
});

export const CreatableMultiSelect: React.FC<ICreatableMultiSelect> = ({
  allOptions,
  name,
  formValue,
  isMulti = true,
  setFieldValue,
  onCreateNewOption,
  onSetCreateNewOptionError: onSetCreateNewHashtagError,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options] = useState<Option[]>(allOptions);

  const handleCreate = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setIsLoading(true);
    onCreateNewOption(name, newOption)
      .then(() => {
        setIsLoading(false);

        setFieldValue(name, [...formValue[name], createOption(inputValue)]);
      })
      .catch(() => {
        setIsLoading(false);
        onSetCreateNewHashtagError("Neuer Tag konnte nicht erstellt werden");

        setFieldValue(name, [...formValue[name]]);
      });
  };

  const isValidNewOption = (inputValue: string, selectValue: Options<Option>) =>
    inputValue.length > 0 && selectValue.length < 5;

  return (
    <CreatableSelect
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      isValidNewOption={isValidNewOption}
      onChange={(option) => {
        setFieldValue(name, option);
      }}
      onCreateOption={handleCreate}
      options={options}
      value={formValue[name]}
      isMulti={isMulti}
      components={{ Menu }}
    />
  );
};

export const Menu = (props: any) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 5 ? (
        props.children
      ) : (
        <div style={{ margin: 15, textAlign: "center" }}>
          Max. 5 Tags sind selektierbar
        </div>
      )}
    </components.Menu>
  );
};
