import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primaryColor: "#0E1C72",
    highlightColor: "#CF36A4",
    primaryTextColor: "#010101",
    secundaryTextColor: "#b1aebb",
  },
  fonts: {
    heading: "Raleway",
    body: "Roboto",
  },
  fontWeights: {
    primaryText: 400,
    boldText: 700,
  },
});

export default theme;

export const headerGradient: Record<string, string> = {
  background:
    "linear-gradient(125deg, #00FF57 0%, #010033 40%, #460043 70%, #F0FFC5 100%), linear-gradient(55deg, #0014C9 0%, #410060 100%), linear-gradient(300deg, #FFC700 0%, #001AFF 100%), radial-gradient(135% 215% at 115% 40%, #393939 0%, #393939 40%, #849561 calc(40% + 1px), #849561 60%, #EED690 calc(60% + 1px), #EED690 80%, #ECEFD8 calc(80% + 1px), #ECEFD8 100%), linear-gradient(125deg, #282D4F 0%, #282D4F 40%, #23103A calc(40% + 1px), #23103A 70%, #A0204C calc(70% + 1px), #A0204C 88%, #FF6C00 calc(88% + 1px), #FF6C00 100%)",
  backgroundBlendMode: "overlay, screen, overlay, overlay, normal",
};
