import { Stack } from "@chakra-ui/react";
import React from "react";
import parse from "html-react-parser";

import { TextSection } from "./TextSection";
import { htmlToReactOptions, MentorFacts } from "..";

interface IMentorTextSection {
  bio: string | null;
  experience: string | null;
  teaching: string | null;
  facts: {
    firstFact: string | null;
    secondFact: string | null;
    thirdFact: string | null;
    fourthFact: string | null;
  };
  testimonials?: string;
}

const mentorHasFacts = (facts: {
  firstFact: string | null;
  secondFact: string | null;
  thirdFact: string | null;
  fourthFact: string | null;
}) => {
  const list = Object.values(facts);
  if (checkFactsForValues(list) === 0) {
    return false;
  }
  return true;
};

const checkFactsForValues = (factsList: (string | null)[]) => {
  let valueCounter: number = 0;
  for (let fact = 0; fact < factsList.length; fact++) {
    if (factsList[fact] === "" || factsList[fact] === null) {
      continue;
    } else {
      valueCounter++;
    }
  }
  return valueCounter;
};

export const MentorDescription: React.FC<IMentorTextSection> = ({
  bio,
  experience,
  teaching,
  facts,
  testimonials,
}) => {
  return (
    <Stack maxW="6xl" pr={[0, 0, 0, 10, 10]} mb={10} spacing={10}>
      <TextSection
        title=""
        parsedHtml={bio ? parse(bio, htmlToReactOptions) : null}
      />
      <TextSection
        title="Erfahrung &amp; Expertise"
        parsedHtml={experience ? parse(experience, htmlToReactOptions) : null}
      />
      {mentorHasFacts(facts) ? <MentorFacts facts={facts} /> : null}
      <TextSection
        title="Workshopinhalte"
        parsedHtml={teaching ? parse(teaching, htmlToReactOptions) : null}
      />
      {testimonials ? (
        <TextSection
          title="Referenzen &amp; Testimonials"
          parsedHtml={parse(testimonials, htmlToReactOptions)}
        />
      ) : null}
    </Stack>
  );
};
