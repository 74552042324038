import { Option } from "..";

const USE_CASES = [
  "futureProofOrganisation",
  "productsServicesAndPositioning",
  "digitalisation",
  "shapeWorkCulture",
  "societyAndEnvironment",
] as const;
export const USE_CASE_CATEGORY_MAPPING: Record<
  typeof USE_CASES[number],
  typeof DB_CATEGORIES[number][]
> = {
  futureProofOrganisation: ["leadership", "strategy", "resilience", "funding"],
  productsServicesAndPositioning: ["innovation", "creativity", "marketing"],
  digitalisation: ["digitalisation", "technology"],
  shapeWorkCulture: ["organisation", "processes"],
  societyAndEnvironment: ["diversity", "sustainability", "wellBeing"],
};

export type ICategoryModel = typeof DB_CATEGORIES[number];

export const DB_CATEGORIES = [
  "leadership",
  "strategy",
  "resilience",
  "funding",
  "innovation",
  "creativity",
  "marketing",
  "digitalisation",
  "technology",
  "organisation",
  "processes",
  "diversity",
  "sustainability",
  "wellBeing",
] as const;

export type DB_CATEGORIES = typeof DB_CATEGORIES;

export enum WORKSHOP_CATEGORY_DE_NAMES {
  strategyAndInnovation = "Strategy & Innovation",
  organisationAndProcesses = "Organisation & Prozesse",
  techAndDigitalisation = "Tech & Digitalisierung",
  leadershipAndResilience = "Leadership & Resilienz",
  diversityAndSustainability = "Diversität & Nachhaltigkeit",
  leadership = "Leadership",
  strategy = "Strategie",
  resilience = "Resilienz",
  funding = "Finanzierung",
  innovation = "Innovation",
  creativity = "Kreativität",
  marketing = "Marketing",
  digitalisation = "Digitalisierung",
  technology = "Technologie",
  organisation = "Organisation",
  processes = "Prozesse",
  diversity = "Diversität",
  sustainability = "Nachhaltigkeit",
  wellBeing = "Wellbeing",
}

export const mapDBCategoryToDECategory = (
  cat: typeof DB_CATEGORIES[number]
) => {
  return WORKSHOP_CATEGORY_DE_NAMES[cat];
};

export const mapDBCategoriesToOptions = (
  dbCategories: DB_CATEGORIES | DB_CATEGORIES[number][]
) => {
  return dbCategories.map((cat) => mapDBCategoryToOption(cat));
};

export const mapDBCategoryToOption = (cat: typeof DB_CATEGORIES[number]) => ({
  label: WORKSHOP_CATEGORY_DE_NAMES[cat],
  value: cat,
});

export const mapCategoryOptionsToDbCategories = (options: Option[]) => {
  return options.map((option) => option.value as DB_CATEGORIES[number]);
};

export const createCategoryUrlParams = (categories: ICategoryModel[]) => {
  return categories
    .map((cat) => {
      return `category=${cat}`;
    })
    .join("&");
};
