import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
} from "@chakra-ui/react";
import Nextlink from "next/link";
import { ReactNode } from "react";
// import { FaLinkedin } from "react-icons/fa";
// import { BiMailSend } from "react-icons/bi";
import Image from "next/image";

const MAIN_LINKS = [
  {
    label: "Mission",
    url: "/mission",
  },
  {
    label: "Angebote",
    url: "/offers",
  },
  {
    label: "Mentoren",
    url: "/mentors",
  },
  {
    label: "Mentor werden",
    url: "/how-it-works/mentors",
  },
  // {
  //   label: "Kontakt",
  //   url: "/contact",
  // },
];

const MENTOR_LINKS = [
  {
    label: "AGBs und Teilnahmebedingungen",
    url: "/terms-and-conditions",
  },
  {
    label: "Impressum",
    url: "/imprint",
  },
  {
    label: "Datenschutz",
    url: "/data-privacy",
  },
  {
    label: "Diversität und Inklusion",
    url: "/diversity-and-inclusion",
  },
];

interface ILogo {
  imgUrl: string;
  width: number;
  height: number;
}

export const Logo: React.FC<ILogo> = ({ imgUrl, width, height }) => {
  return (
    <Image src={imgUrl} alt="mentorlab logo" width={width} height={height} />
  );
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export const Footer = () => {
  return (
    <Box
      bg={useColorModeValue("black", "gray.900")}
      color={useColorModeValue("white", "gray.200")}
      mt={24}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Logo
                imgUrl="/logo/white/ML_logo_lndscp_wht.svg"
                width={160}
                height={80}
              />
            </Box>
            <Text fontSize={"sm"}>
              © 2023 mentorlab. Alle Rechte vorbehalten.
            </Text>
            {/* <Stack direction={"row"} spacing={6}>
              <SocialButton label={"LinkedIn"} href={"#"}>
                <FaLinkedin />
              </SocialButton>
            </Stack> */}
          </Stack>
          <Stack align={"flex-start"}>
            {/* <ListHeader>mentorlab</ListHeader> */}
            {MAIN_LINKS.map((link, index) => (
              <Nextlink key={index} href={link.url}>
                {link.label}
              </Nextlink>
            ))}
          </Stack>
          <Stack align={"flex-start"}>
            {/* <ListHeader>Für Mentoren</ListHeader> */}
            {MENTOR_LINKS.map((link, index) => (
              <Nextlink key={index} href={link.url}>
                {link.label}
              </Nextlink>
            ))}
          </Stack>
          {/* <Stack align={"flex-start"}>
            <ListHeader>Bleib auf dem Laufenden</ListHeader>
            <Stack direction={"row"}>
              <Input
                placeholder={"Your email address"}
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
              />
              <IconButton
                bg={useColorModeValue("purple.400", "purple.800")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "purple.600",
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
              />
            </Stack>
          </Stack> */}
        </SimpleGrid>
      </Container>
    </Box>
  );
};
