import { ICategoryModel } from "../categoryModel";

export enum EFORMAT {
  IMPULSVORTRAG = "Impulsvortrag",
  WORKSHOP = "Workshop",
  TRAINING = "Training",
  SONSTIGES = "sonstiges",
}
export type Format = keyof typeof EFORMAT;

export enum ECOMPANYSIZE {
  STARTUP = "Startup",
  KMU = "KMU",
  MITTELSTAND = "Mittelstand",
  KONZERN = "Konzern",
}
export type CompanySize = keyof typeof ECOMPANYSIZE;

export enum ESTATUS {
  Available = "Verfügbar",
  Archived = "Abgelaufen",
}
export type JobStatus = keyof typeof ESTATUS;

export interface IDbJobListingModel {
  company: string;
  email: string;
  contact: string;
  location: string;
  format: Format;
  title: string;
  languages: string[];
  industry: string;
  companySize: CompanySize;
  shortDescription: string;
  description: string;
  dateTime: string;
  price: string;
  categories: ICategoryModel[];
  hashtags: string[];
  preferedExpertise: string;
  status: JobStatus;
  interestedMentors: string[];
  accepted: boolean;
  verified: boolean;
  postedAt: string | null;
  createdAt: string;
  updatedAt: string;
}
