import React from "react";
import { FormControl, Box, Divider, Text } from "@chakra-ui/react";
import { Field } from "formik";
import { MultiSelect, HeaderDescriptionFormFieldWrapper, Option } from "..";

interface IMultiSelectFormField {
  name: string;
  title: string;
  description?: string;
  isMulti?: boolean;
  allOptions: Option[];
}

export const MultiSelectFormField: React.FC<IMultiSelectFormField> = ({
  name,
  title,
  description,
  allOptions,
  isMulti = true,
}) => {
  return (
    <Box>
      <Field name={name}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={form.errors[name] && form.touched[name]}
          >
            <HeaderDescriptionFormFieldWrapper
              title={title}
              description={description}
            >
              <Box mt={2}>
                <MultiSelect
                  {...field}
                  id={name}
                  formValue={form.values}
                  setFieldValue={form.setFieldValue}
                  allOptions={allOptions}
                  isMulti={isMulti}
                />
              </Box>
              {form.errors[name] && (
                <Text mt={1} fontSize={"sm"} color={"red.400"}>
                  {form.errors[name]}
                </Text>
              )}
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
      <Divider mb={10} />
    </Box>
  );
};
