import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Link,
} from "@chakra-ui/react";

interface ICookiePolicyModal {
  isOpen: boolean;
  onClose: () => void;
}

export const CookiePolicyModal: React.FC<ICookiePolicyModal> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader textAlign={"center"}>Verwendung von Cookies</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={8}>
            <Text fontSize={"lg"} fontWeight={"bold"} mb={4}>
              Was sind Cookies und welche Arten gibt es?
            </Text>
            <Text mb={2}>
              Cookies (kleine Schlüssel-Wert-Paare bzw. Datepakete) werden von
              Webseiten zur Identifikation von Nutzern, schalten von
              individueller Werbung, Analysen, etc. verwendet und gespeichert.
              Hierbei ist zwischen funktionalen Cookies, die für den
              grundlegenden Betrieb der Webseite benötigt werden und
              Marketing-Cookies, die für Vermarktungszwecke zum Einsatz kommen,
              zu differenzieren.
            </Text>
            <Text>
              <Link
                textDecor={"underline"}
                color={"primaryColor"}
                href="https://mentorlab.de"
              >
                mentorlab.de
              </Link>{" "}
              verwendet hierbei ausschließlich funktionale Cookies und keine
              Marketing-Cookies.
            </Text>
          </Box>
          <Box>
            <Text fontSize={"lg"} fontWeight={"bold"} mb={4}>
              Was verwendet mentorlab.de?
            </Text>
            <Box mb={4}>
              <Text fontWeight={"bold"}>Session Cookie</Text>
              <Text>
                Dieser funktionale Cookie wird verwendet, um eine laufende
                Session (Besuch bzw. browsen der Webseite) einem Nutzer
                zuzuordnen. Session Cookies werden jedoch gelöscht, sobald eine
                Session beendet und der Browser geschlossen wurde.
              </Text>
            </Box>
            <Box>
              <Text fontWeight={"bold"}>Local Storage</Text>
              <Text>
                Im lokalen Speicher des Browsers eines Nutzers wird
                abgespeichert, ob der Nutzer von{" "}
                <Link
                  textDecor={"underline"}
                  color={"primaryColor"}
                  href="https://mentorlab.de"
                >
                  mentorlab.de
                </Link>{" "}
                den Cookie-Hinweis geschlossen hat. Dadurch wird verhindert,
                dass der Cookie-Hinweis, nachdem er geschlossen wurde, auf allen
                weiteren Seiten erneut erscheint und die User Experience negativ
                beeinträchtigt.
              </Text>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
