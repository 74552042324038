import { Heading, Box, Text } from "@chakra-ui/react";
import React from "react";

interface IHeaderDescriptionFormFieldWrapper {
  title: string;
  children: React.ReactNode;
  description?: string;
}

export const HeaderDescriptionFormFieldWrapper: React.FC<
  IHeaderDescriptionFormFieldWrapper
> = ({ title, description, children }) => {
  return (
    <>
      <Heading fontSize={"md"}>{title}</Heading>
      <Box mt={1} mb={2}>
        {description ? (
          <Text fontSize={"sm"} color={"rgb(113, 113, 113)"}>
            {description}
          </Text>
        ) : null}

        {children}
      </Box>
    </>
  );
};
