import { Option } from "..";

export interface IHashtagModel {
  name: string;
}

export const DB_HASHTAGS = [
  "kundenzentrierung",
  "servicedesign",
  "designthinking",
  "change",
  "innovation",
  "okr",
  "arbeitsmethoden",
  "produktdesign",
  "lean",
  "agile",
  "personalbranding",
  "leadership",
  "achtsamkeit",
  "gesundheit",
  "compliance",
  "teamentwicklung",
  "finance",
  "hr",
  "seo",
  "karriere",
  "digitalisierung",
  "künstliche intelligenz",
  "machine learning",
  "blockchain",
  "metaverse",
  "data",
] as const;

export type DB_HASHTAGS = typeof DB_HASHTAGS;

export const mapDBHastagsToOptions = (
  dbCategories: DB_HASHTAGS | DB_HASHTAGS[number][]
) => {
  return dbCategories.map((cat) => mapDBHastagToOption(cat));
};

export const mapDBHastagToOption = (cat: typeof DB_HASHTAGS[number]) => ({
  label: cat,
  value: cat,
});

export const mapHastagOptionsToDbCategories = (options: Option[]) => {
  return options.map((option) => option.value as DB_HASHTAGS[number]);
};
