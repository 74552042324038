import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import { CookieBanner, CookiePolicyModal } from "..";

export const CookieNotification = () => {
  const {
    onOpen: onOpenCookiePolicyModal,
    onClose: onCloseCookiePolicyModal,
    isOpen: isOpenCookiePolicyModal,
  } = useDisclosure();
  return (
    <>
      <CookieBanner showDetails={onOpenCookiePolicyModal} />
      <CookiePolicyModal
        isOpen={isOpenCookiePolicyModal}
        onClose={onCloseCookiePolicyModal}
      />
    </>
  );
};
