import { HTMLReactParserOptions, Element, domToReact } from "html-react-parser";
import { Text, ListItem, UnorderedList, OrderedList } from "@chakra-ui/react";
import React from "react";

export const htmlToReactOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (
      domNode instanceof Element &&
      domNode.name === "p" &&
      domNode.children.length === 0
    ) {
      return <br />;
    }
    if (
      domNode instanceof Element &&
      domNode.name === "p" &&
      domNode.children.length !== 0
    ) {
      return <Text>{domToReact(domNode.children)}</Text>;
    }
    if (domNode instanceof Element && domNode.name === "ul") {
      return (
        <UnorderedList pl={3}>{domToReact(domNode.children)}</UnorderedList>
      );
    }
    if (domNode instanceof Element && domNode.name === "ul") {
      return <ListItem>{domToReact(domNode.children)}</ListItem>;
    }
    if (domNode instanceof Element && domNode.name === "ol") {
      return <OrderedList pl={3}>{domToReact(domNode.children)}</OrderedList>;
    }
    if (domNode instanceof Element && domNode.name === "ol") {
      return <ListItem>{domToReact(domNode.children)}</ListItem>;
    }
  },
};
