import React, { useState } from "react";

import Select, { MultiValue, SingleValue } from "react-select";
import { Menu } from "../CreatableMultiSelect/CreatableMultiSelect";
import { Option } from "../types/workshopModel";

interface IMultiSelect {
  name: string;
  formValue: any;
  isMulti?: boolean;
  setFieldValue: (
    field: string,
    selectedOptions: MultiValue<Option> | SingleValue<Option>
  ) => void;
  allOptions: Option[];
}

export const MultiSelect: React.FC<IMultiSelect> = ({
  allOptions,
  name,
  formValue,
  isMulti = true,
  setFieldValue,
}) => {
  const [options] = useState<Option[]>(allOptions);
  return (
    <Select
      isMulti={isMulti}
      options={options}
      classNamePrefix="select"
      onChange={(option) => {
        setFieldValue(name, option);
      }}
      value={formValue[name]}
      components={{ Menu }}
    />
  );
};
