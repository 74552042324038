import React from "react";
import { useEditor, EditorContent, Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import { Box, Button, Divider, Text } from "@chakra-ui/react";
import "./styles.css";

interface IMenuBar {
  editor: Editor;
  withButtons: boolean;
}

const MenuBar: React.FC<IMenuBar> = ({ editor, withButtons }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      {withButtons ? (
        <>
          <Button
            variant={"outline"}
            backgroundColor={"gray.300"}
            as={"kbd"}
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive("paragraph") ? "is-active" : ""}
          >
            Text
          </Button>

          <Button
            variant={"outline"}
            backgroundColor={"gray.300"}
            as={"kbd"}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            Stichpunktliste
          </Button>

          <Divider pt={4} colorScheme={"blue"} />
        </>
      ) : null}
    </>
  );
};

interface IRichTextEditor {
  setFieldValue: (field: string, text: string) => void;
  fieldName: string;
  initialValue: Record<string, unknown>;
  characterLimit: number;
  withButtons: boolean;
}
export const RichTextEditor: React.FC<IRichTextEditor> = ({
  setFieldValue,
  initialValue,
  fieldName,
  characterLimit,
  withButtons = false,
}) => {
  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          history: false,
        }),
        CharacterCount.configure({
          limit: characterLimit,
        }),
      ],

      onUpdate: ({ editor }) => {
        setFieldValue(fieldName, editor.getHTML());
      },
      content: initialValue,
    },
    [initialValue]
  );

  if (!editor) {
    return null;
  }

  return (
    <Box
      borderRadius={"lg"}
      backgroundColor={"gray.100"}
      _focus={{ outline: "none" }}
      p={4}
    >
      <MenuBar editor={editor} withButtons={withButtons} />
      {/* @ts-ignore */}
      <EditorContent editor={editor} />
      <Box className="character-count">
        <Text
          textColor={
            editor?.storage.characterCount.characters() === characterLimit
              ? "red"
              : "gray:500"
          }
        >
          {editor?.storage.characterCount.characters()}/{characterLimit} Zeichen
        </Text>
      </Box>
    </Box>
  );
};
