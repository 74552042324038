import { Button as ChakraButton } from "@chakra-ui/button";
import * as React from "react";

type ButtonType = "solid" | "outline" | "link" | "ghost";
interface IButton {
  label: string;
  type: ButtonType;
  display?: {
    base: string;
    md: string;
  };
  width?: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}
export const Button: React.FC<IButton> = ({
  label,
  type,
  onClick,
  width,
  isLoading = false,
  isDisabled = false,
  display,
}) => {
  return (
    <ChakraButton
      display={display}
      width={width}
      backgroundColor={type === "solid" ? "highlightColor" : "none"}
      color={type === "solid" ? "white" : "primaryColor"}
      variant={type}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      _hover={
        type !== "link"
          ? { bg: "highlightColor", color: "white", border: "none" }
          : {}
      }
    >
      {label}
    </ChakraButton>
  );
};
