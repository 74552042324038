import { DB_CATEGORIES, DB_HASHTAGS, DB_LANGUAGES } from "..";
import { Option } from "../types/workshopModel";

export const mapOptionToArray = (option: Option[]) => {
  return option.map((option) => ({ name: option.value }));
};

export const mapDbArrayToOptions = (
  values: { id?: string; name: string }[]
): Option[] => {
  return values.map((value) => ({
    label: value.name,
    value: value.id ? value.id : value.name,
  }));
};

export const mapArrayToOptions = (
  values: DB_CATEGORIES | DB_HASHTAGS | DB_LANGUAGES
): Option[] => {
  return values.map((value) => ({
    label: value,
    value,
  }));
};
