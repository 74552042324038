import { Stack, Avatar, Text } from "@chakra-ui/react";
import React from "react";
import Link from "next/link";
import type { IMentorModel } from "ui";

type IMentorInformation = Pick<
  IMentorModel,
  "id" | "firstName" | "lastName" | "company" | "jobTitle" | "imageUrl"
> & {
  hasBackground?: boolean;
};

export const MentorInformation: React.FC<IMentorInformation> = ({
  id,
  firstName,
  lastName,
  company,
  jobTitle,
  imageUrl,
  hasBackground = false,
}) => {
  return (
    <Link href={`/mentors/${id}`}>
      <Stack
        mt={6}
        direction={"row"}
        spacing={4}
        align={"center"}
        cursor={"pointer"}
      >
        <Avatar
          src={imageUrl || ""}
          alt={`${firstName} ${lastName}`}
          outline={"2px solid white"}
        />
        <Stack
          direction={"column"}
          spacing={0}
          fontSize={"sm"}
          color={hasBackground ? "white" : "black"}
        >
          <Text
            fontWeight={600}
            color={hasBackground ? "white" : "black"}
          >{`${firstName} ${lastName}`}</Text>
          <Text
            noOfLines={1}
            color={hasBackground ? "white" : "gray.500"}
          >{`${jobTitle}`}</Text>
          <Text
            noOfLines={1}
            color={hasBackground ? "white" : "gray.500"}
          >{`@${company}`}</Text>
        </Stack>
      </Stack>
    </Link>
  );
};
