import { FormControl, Box, Divider, Text } from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import { HeaderDescriptionFormFieldWrapper } from "..";
import { RichTextEditor } from "../RichTextEditor/RichTextEditor";

interface IRichTextFormField {
  name: string;
  title: string;
  description?: string;
  initialValue?: string | null;
  characterLimit: number;
  withButtons: boolean;
}

export const RichTextFormField: React.FC<IRichTextFormField> = ({
  name,
  title,
  description,
  initialValue,
  characterLimit,
  withButtons,
}) => {
  return (
    <Box>
      <Field name={name}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={form.errors[name] && form.touched[name]}
          >
            <HeaderDescriptionFormFieldWrapper
              title={title}
              description={description}
            >
              <Box mt={2}>
                <RichTextEditor
                  {...field}
                  id={name}
                  setFieldValue={form.setFieldValue}
                  initialValue={initialValue}
                  fieldName={name}
                  characterLimit={characterLimit}
                  withButtons={withButtons}
                />
              </Box>
              {form.errors[name] && (
                <Text mt={1} fontSize={"sm"} color={"red.400"}>
                  {form.errors[name]}
                </Text>
              )}
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
      <Divider mb={10} />
    </Box>
  );
};
