import {
  Box,
  Heading,
  Text,
  Image,
  UnorderedList,
  ListItem,
  Stack,
  Tag,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import React from "react";

interface IJobBoard {
  corporateImageUrl: string;
  mentorImageUrl: string;
  corporateAndMentorImageUrl: string;
  withDefaultHeaderDescription?: boolean;
  alternativeTitle?: string;
}

export const JobBoard: React.FC<IJobBoard> = ({
  corporateImageUrl,
  mentorImageUrl,
  corporateAndMentorImageUrl,
  withDefaultHeaderDescription = true,
  alternativeTitle,
}) => {
  const steps: IStep[] = [
    {
      imageUrl: corporateImageUrl,
      config: {
        step: "1.Schritt",
        who: "Unternehmen",
        firstListItem: "Job beschreiben",
        secondListItem: "Frist eingeben",
        thirdListItem: "Ausschreibung einstellen",
      },
    },
    {
      imageUrl: mentorImageUrl,
      config: {
        step: "2.Schritt",
        who: "Mentor",
        firstListItem: "Ausschreibungen lesen",
        secondListItem: "Interesse bekunden",
        thirdListItem: "Auf Kontaktaufnahme warten",
      },
    },

    {
      imageUrl: corporateAndMentorImageUrl,
      config: {
        step: "3.Schritt",
        who: "Unternehmen & Mentor",
        firstListItem: "Unternehmen wählen geeignete Kandidaten aus",
        secondListItem: "Unternehmen kontaktieren Mentoren",
        thirdListItem: "Start ins gemeinsame Engagement",
      },
    },
  ];
  return (
    <Box
      p={{ base: 4, md: 12 }}
      background={"gray.50"}
      borderRadius={8}
      mt={10}
    >
      {alternativeTitle && (
        <>
          <Heading as="h2" mb="4">
            {alternativeTitle}
          </Heading>
          <Text mb={8}>
            Hinweis: Ausschreibungen von Unternehmen sind für eingeloggte
            Mentoren im Admin-Bereich sichtbar, für die Bewerbung ist ab Oktober
            2023 eine kostenpflichtige{" "}
            <Link
              href="https://mentorlab.de/how-it-works/mentors"
              textDecor={"underline"}
              color={"primaryColor"}
            >
              Premium-Mitgliedschaft
            </Link>{" "}
            erforderlich. Tipp: Mentoren können diese Funktionen ebenfalls
            nutzen, um z.B. Partner für Projekte zu suchen.
          </Text>
        </>
      )}

      {withDefaultHeaderDescription && (
        <>
          <Heading as="h2" mb="12">
            Job Board (beta)
          </Heading>
          <Text mb={10}>
            Matchmaking leicht gemacht: Auf dem Job Board posten Unternehmen
            Anfragen für Workshops, Trainings, Coachings und Projekte. Mentoren
            mit dem passenden Profil können sich innerhalb der
            Ausschreibungsfrist ganz unkompliziert auf die ausgeschriebenen Jobs
            bewerben. Unternehmen erhalten innerhalb von 24 Stunden nach Ablauf
            der Frist von mentorlab die eingegangenen Mentorenprofile.
          </Text>
        </>
      )}
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        justifyContent={"center"}
        spacing={{ base: 14, md: 10 }}
      >
        {steps.map((step, index) => (
          <Step
            key={`${step.config.step}-${index}`}
            imageUrl={step.imageUrl}
            config={step.config}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

interface IStep {
  imageUrl: string;
  config: {
    step: "1.Schritt" | "2.Schritt" | "3.Schritt";
    who: "Unternehmen" | "Mentor" | "Unternehmen & Mentor";
    firstListItem: string;
    secondListItem: string;
    thirdListItem: string;
  };
}
const Step: React.FC<IStep> = ({ imageUrl, config }) => (
  <Stack alignItems={"flex-start"}>
    <Box pos={"relative"}>
      <Tag
        pos={"absolute"}
        backgroundColor={"highlightColor"}
        color="white"
        top={2}
        left={2}
      >
        {config.step}
      </Tag>
      <Image
        objectFit="contain"
        h={"xs"}
        w={"xs"}
        src={imageUrl}
        alt="mentorlab - Unternehmen mit Job Board"
      />
    </Box>
    <Box pl={{ base: 2 }}>
      <Text fontWeight={700} fontSize={"md"} pb={4}>
        {config.who}
      </Text>
      <UnorderedList>
        <ListItem>{config.firstListItem}</ListItem>
        <ListItem>{config.secondListItem}</ListItem>
        <ListItem>{config.thirdListItem}</ListItem>
      </UnorderedList>
    </Box>
  </Stack>
);
