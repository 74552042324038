import { ReactNode } from "react";
import NextLink from "next/link";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import React from "react";

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export const PricingOverview: React.FC = () => {
  return (
    <Box>
      <VStack spacing={2} textAlign="center">
        <Heading pb={6} as="h2">
          Für jeden die passende Mitgliedschaft
        </Heading>
        <Text fontWeight={"bold"}>
          Für Schnellstarter: Jetzt anmelden und bis September 2023 mit der
          Basic-Mitgliedschaft Premiumvorteile genießen
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Basic
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                €
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                0
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /Jahr
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Mentorenprofil
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />5
                Workshop-Angebote einstellen
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Job Board einsehen
              </ListItem>
              <ListItem>
                <ListIcon as={FaTimesCircle} color="red.500" />
                Auf Job Board bewerben*
              </ListItem>
              <ListItem>
                <ListIcon as={FaTimesCircle} color="red.500" />
                Trainingspaket
              </ListItem>
              <ListItem>
                <ListIcon as={FaTimesCircle} color="red.500" />
                Vermarktungspaket
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <NextLink href={"https://portal.mentorlab.de/signup"} passHref>
                <Button w="full" bg="highlightColor" color="white">
                  Jetzt registrieren
                </Button>
              </NextLink>
            </Box>
          </VStack>
        </PriceWrapper>
        <PriceWrapper>
          <Box position="relative">
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Premium
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  €
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  99
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /Jahr
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Mentorenprofil
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Unlimitierte Workshop-Angebote einstellen
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Job Board einsehen
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Auf Job Board bewerben
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Basic Trainingspaket
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Basic Vermarktungspaket
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Ab Oktober 2023 zum Einführungspreis von 99,00 €/Jahr
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button
                  variant={"outline"}
                  w="full"
                  disabled
                  borderColor={"primaryColor"}
                >
                  Buchbar ab Oktober 2023
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>

        {/* <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Premium Plus
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                399
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /Jahr
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Mentorenprofil
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Unlimitierte Workshop-Angebote einstellen
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Job Board einsehen
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Mit Early Access auf Job Board bewerben
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Erweitertes Trainingspaket
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Erweitertes Vermarktungspaket
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button
                disabled
                w="full"
                borderColor={"primaryColor"}
                variant="outline"
              >
                Coming soon
              </Button>
            </Box>
          </VStack>
        </PriceWrapper> */}
      </Stack>
      <Text>*Premium-Features bis September 2023 kostenlos nutzen!</Text>
    </Box>
  );
};
