import {
  Box,
  FormControl,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import { HeaderDescriptionFormFieldWrapper } from "..";
import { splitFieldName } from "../utils/formValidationHelper";

interface IIncrementNumberFormField {
  name: string;
  title: string;
  description?: string;
}
export const IncrementNumberFormField: React.FC<IIncrementNumberFormField> = ({
  name,
  title,
  description,
}) => {
  const splittedFieldName = splitFieldName(name);
  return (
    <Box>
      <Field name={name}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={
              splittedFieldName.secondPart
                ? form.errors[splittedFieldName.firstPart]?.[
                    splittedFieldName.secondPart
                  ] &&
                  form.touched[splittedFieldName.firstPart]?.[
                    splittedFieldName.secondPart
                  ]
                : form.errors[splittedFieldName.firstPart] &&
                  form.touched[splittedFieldName.firstPart]
            }
          >
            <HeaderDescriptionFormFieldWrapper
              title={title}
              description={description}
            >
              <Box mt={2}>
                <NumberInput size="lg" maxW={32} min={1} {...field}>
                  <NumberInputField
                    onChange={form.handleChange}
                    id={name}
                    variant="filled"
                    value={field.value}
                  />
                </NumberInput>
              </Box>
              <FormErrorMessage>
                {splittedFieldName.secondPart
                  ? form.errors[splittedFieldName.firstPart]?.[
                      splittedFieldName.secondPart
                    ]
                  : form.errors[splittedFieldName.firstPart]}
              </FormErrorMessage>
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
    </Box>
  );
};
