import { Box, Button, Container, Heading, HStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import type { NextRouter } from "next/router";
import React, { useEffect, useState } from "react";
import {
  IDbMentorModel,
  mapDbArrayToOptions,
  mapDBCategoriesToOptions,
} from "../..";
import { IDbWorkshopModel } from "../../types/db/dbWorkshopModel";
import { IMentorProfileFormData } from "../../types/FormData/MentorProfileFormData";

const isMentorModel = (
  initialFormValue: IDbMentorModel | IDbWorkshopModel
): initialFormValue is IDbMentorModel => {
  return (initialFormValue as IDbMentorModel).firstName !== undefined;
};

export type StepConfig = {
  [key: string]: { title: string; content: React.ReactNode };
};

interface IMultistepForm {
  initialFormValue?: IDbMentorModel;
  initialState: IMentorProfileFormData;
  validationSchema: unknown;
  steps: StepConfig;
  navigationRouter: NextRouter;
  onSubmit: (formData: IMentorProfileFormData) => Promise<void>;
}

export const MultistepForm: React.FC<IMultistepForm> = ({
  initialFormValue,
  initialState,
  navigationRouter,
  steps,
  validationSchema,
  onSubmit,
}) => {
  const formStepArray = Object.keys(steps);
  const [step, setStep] = useState(formStepArray[0]);
  const [isLast, setIsLast] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const currentIndex = formStepArray.indexOf(step);

  const [initialFormData, setInitialFormData] =
    useState<IMentorProfileFormData>();
  useEffect(() => {
    if (initialFormValue) {
      if (isMentorModel(initialFormValue)) {
        const initialMentorFormData: IMentorProfileFormData = {
          id: initialFormValue.id,
          company: initialFormValue.company,
          jobTitle: initialFormValue.jobTitle,
          firstName: initialFormValue.firstName,
          lastName: initialFormValue.lastName,
          city: initialFormValue.city,
          languages: initialFormValue.languages
            ? mapDbArrayToOptions(initialFormValue.languages)
            : [],
          active: initialFormValue.active,
          linkedIn: initialFormValue.linkedIn,
          xing: initialFormValue.xing,
          bio: initialFormValue.bio,
          experience: initialFormValue.experience,
          teaching: initialFormValue.teaching,
          hashtags: initialFormValue.hashtags
            ? mapDbArrayToOptions(initialFormValue.hashtags)
            : [],
          categories: initialFormValue.categories
            ? mapDBCategoriesToOptions(initialFormValue.categories)
            : [],
          firstFact: initialFormValue.facts.firstFact,
          secondFact: initialFormValue.facts.secondFact,
          thirdFact: initialFormValue.facts.thirdFact,
          fourthFact: initialFormValue.facts.fourthFact,
          testimonials: initialFormValue.testimonials!,
        };
        setInitialFormData(initialMentorFormData);
      }
    }
  }, [initialFormValue]);

  const nextStep = () => {
    setIsFirst(false);
    if (isLastStep(currentIndex)) {
      // set to last step
      setIsLast((curr) => (curr = true));
      setStep(formStepArray[formStepArray.length - 1]);
    } else {
      setIsLast(false);
      setStep(formStepArray[currentIndex + 1]);
    }
  };

  const beforeStep = () => {
    setIsLast(false);
    if (isFirstStep(currentIndex)) {
      // set to first step
      setIsFirst((curr) => (curr = true));
      setStep(formStepArray[0]);
    } else {
      setIsFirst(false);
      setStep(formStepArray[currentIndex - 1]);
    }
  };

  const isLastStep = (currentStepIndex: number) => {
    return currentStepIndex >= formStepArray.length - 2;
  };

  const isFirstStep = (currentStepIndex: number) => currentStepIndex <= 1;

  const calcProgressBarLength = () => {
    const length = formStepArray.length - 1;
    const currentStepIndex = formStepArray.indexOf(step);
    const result = (currentStepIndex / length) * 100;
    return result === 0 ? 0.1 : result;
  };

  const submit = async (values: any) => {
    await onSubmit(values);
  };
  const saveAndExit = async (values: IMentorProfileFormData) => {
    try {
      await onSubmit(values);
      setTimeout(() => navigationRouter.push("/profile"), 2000);
    } catch (error) {}
  };
  return (
    <>
      <Box
        maxW={"4xl"}
        w="100%"
        h={"10px"}
        backgroundColor={"gray.200"}
        margin={"0 auto"}
        my={10}
      >
        <Box
          w={`${calcProgressBarLength()}%`}
          h={"10px"}
          backgroundColor={"primaryColor"}
          transition={"width 0.5s ease-in-out"}
        />
      </Box>
      <>
        {/* {formStepArray.map((step) => (
          <Button variant={"link"} onClick={() => setStep(step)}>
            {step}
          </Button>
        ))} */}
        <Container maxW={"4xl"} pos={"relative"} p={0}>
          <Heading as="h2" size={"md"} fontWeight={700} mb={8}>
            {steps[step].title}
          </Heading>
          <Box pb={10}>
            <Formik
              initialValues={initialFormData ?? initialState}
              onSubmit={submit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {(formik) => {
                return (
                  <Form>
                    {steps[step].content}

                    <HStack spacing={2}>
                      <Button
                        // isLoading={formik.isSubmitting}
                        type="submit"
                        disabled={isFirst || !formik.isValid}
                        onClick={beforeStep}
                      >
                        Speichern und zurück
                      </Button>
                      <Button
                        backgroundColor={isLast ? "primaryColor" : "gray.100"}
                        color={isLast ? "white" : "black.700"}
                        // isLoading={formik.isSubmitting}
                        type="submit"
                        disabled={
                          !formik.isValid ||
                          (formik.values.firstName?.length === 0 &&
                            formik.values.lastName?.length === 0)
                        }
                        onClick={() => {
                          isLast ? saveAndExit(formik.values) : nextStep();
                        }}
                      >
                        {isLast
                          ? `Speichern und beenden`
                          : "Speichern und weiter"}
                      </Button>
                    </HStack>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </>
    </>
  );
};
