import React, { useEffect, useState } from "react";
import { Stack, Text, Button } from "@chakra-ui/react";
import { FcLock } from "react-icons/fc";

interface ICookieBanner {
  showDetails: () => void;
}
export const CookieBanner: React.FC<ICookieBanner> = ({ showDetails }) => {
  const cookiesLocalStorageAcceptedKey = "acceptedFunctionalCookies";
  const [isClosed, setIsClosed] = useState<boolean>(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const isCookiesAcceptedFromLocalStorage =
        localStorage.getItem(cookiesLocalStorageAcceptedKey) === "yes";
      setIsClosed(isCookiesAcceptedFromLocalStorage);
    }
  }, []);

  const onClose = () => {
    setIsClosed(true);
    localStorage.setItem(cookiesLocalStorageAcceptedKey, "yes");
  };

  if (isClosed) {
    return null;
  }

  return (
    <Stack
      bottom={0}
      position={"sticky"}
      zIndex={10}
      p="4"
      boxShadow="xl"
      m="4"
      borderRadius="8px 8px 0px 0px"
      backgroundColor={"primaryColor"}
      color={"white"}
    >
      <Stack direction="row" alignItems="center">
        <Text fontWeight="semibold">Your Privacy</Text>
        <FcLock />
      </Stack>

      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Text fontSize={{ base: "sm" }} textAlign={"left"} maxW={"4xl"}>
          Wir verwenden essentielle Cookies und ähnliche Technologien, um die
          Funktionsweise der Webseite zu garantieren und ein besseres Erlebnis
          zu bieten. Diese werden nicht für Tracking verwendet.
        </Text>
        <Stack direction={{ base: "column", md: "row" }}>
          <Button variant="outline" colorScheme="white" onClick={showDetails}>
            Mehr erfahren
          </Button>
          <Button
            variant="solid"
            backgroundColor={"highlightColor"}
            color={"white"}
            onClick={onClose}
          >
            Verstanden
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
