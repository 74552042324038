import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import { Analytics } from "@vercel/analytics/react";
import "@fontsource/raleway/700.css";
import "@fontsource/roboto/400.css";
import theme from "../styles/customTheme";
import { ErrorBoundary } from "../src/components/ErrorBoundary/ErrorBoundary";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
      <Analytics />
    </ChakraProvider>
  );
}

export default MyApp;
