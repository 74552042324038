import { Box, Divider, FormControl, Text } from "@chakra-ui/react";
import { Field, FieldAttributes } from "formik";
import React from "react";
import { HeaderDescriptionFormFieldWrapper } from "..";
import { splitFieldName } from "../utils/formValidationHelper";

interface IFormFieldWrapper {
  title: string;
  child: React.ReactNode;
  inputConfig: {
    name: string;
    placeholder?: string;
  };
  description?: string;
  isLastInput?: boolean;
}

export const FormFieldWrapper: React.FC<IFormFieldWrapper> = ({
  title,
  description,
  inputConfig,
  child,
  isLastInput = false,
}) => {
  const splittedFieldName = splitFieldName(inputConfig.name);

  const renderFormInput = (
    field: FieldAttributes<any>,
    form: FieldAttributes<any>
  ) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...field,
        onChange: form.handleChange,
        id: inputConfig.name,
        placeholder: inputConfig.placeholder ?? "",
        variant: "filled",
        mt: 2,
      });
    } else {
      return null;
    }
  };
  return (
    <Box>
      <Field name={inputConfig.name}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={
              splittedFieldName.secondPart
                ? form.errors[splittedFieldName.firstPart]?.[
                    splittedFieldName.secondPart
                  ] &&
                  form.touched[splittedFieldName.firstPart]?.[
                    splittedFieldName.secondPart
                  ]
                : form.errors[splittedFieldName.firstPart] &&
                  form.touched[splittedFieldName.firstPart]
            }
          >
            <HeaderDescriptionFormFieldWrapper
              title={title}
              description={description}
            >
              {renderFormInput(field, form)}
            </HeaderDescriptionFormFieldWrapper>

            <Text mt={1} fontSize={"sm"} color={"red.400"}>
              {splittedFieldName.secondPart
                ? form.errors[splittedFieldName.firstPart]?.[
                    splittedFieldName.secondPart
                  ]
                : form.errors[splittedFieldName.firstPart]}
            </Text>
          </FormControl>
        )}
      </Field>
      {!isLastInput ? <Divider mb={10} /> : null}
    </Box>
  );
};
