import { FormControl, Box, Divider, Switch, Text } from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import { HeaderDescriptionFormFieldWrapper } from "..";

interface IToggleFormField {
  name: string;
  title: string;
  description?: string;
  disableActivation: boolean;
}

export const ToggleFormField: React.FC<IToggleFormField> = ({
  name,
  title,
  description,
  disableActivation,
}) => {
  return (
    <Box>
      <Field name={name}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={form.errors[name] && form.touched[name]}
          >
            <HeaderDescriptionFormFieldWrapper
              title={title}
              description={description}
            >
              <Box mt={2}>
                <Switch
                  {...field}
                  setFieldValue={form.setFieldValue}
                  isChecked={field.value}
                  id={name}
                  isDisabled={disableActivation}
                />
                {disableActivation && (
                  <Text color={"red.400"} mt={2} fontSize={"sm"}>
                    Du kannst den Workshop erst aktiv schalten, sobald du dein
                    Mentorenprofil aktiviert hast.
                  </Text>
                )}
              </Box>
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
      <Divider mb={10} />
    </Box>
  );
};
