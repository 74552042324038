import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

interface ITextSection {
  title: string;
  text?: string | null;
  parsedHtml?: string | JSX.Element | JSX.Element[] | null;
  children?: JSX.Element;
}
export const TextSection: React.FC<ITextSection> = ({
  title,
  text,
  parsedHtml,
  children,
}) => {
  return (
    <Box>
      <Heading as="h2" size="md" mb={4}>
        {title}
      </Heading>
      {text ? <Text>{text}</Text> : null}
      {parsedHtml ? parsedHtml : null}
      {children ? children : null}
    </Box>
  );
};
