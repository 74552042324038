import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { PageContainer } from "../ui/PageContainer";

interface IErrorFallback {
  firstLineText: string;
  secondLineText: string;
}
export const ErrorFallback: React.FC<IErrorFallback> = ({
  firstLineText,
  secondLineText,
}) => {
  const router = useRouter();
  return (
    <Box
      style={{
        background:
          "linear-gradient(123deg, #FFFFFF 0%, #00B2FF 100%), linear-gradient(236deg, #BAFF99 0%, #005E64 100%), linear-gradient(180deg, #FFFFFF 0%, #002A5A 100%), linear-gradient(225deg, #0094FF 20%, #BFF4ED 45%, #280F34 45%, #280F34 70%, #FF004E 70%, #E41655 85%, #B30753 85%, #B30753 100%), linear-gradient(135deg, #0E0220 15%, #0E0220 35%, #E40475 35%, #E40475 60%, #48E0E4 60%, #48E0E4 68%, #D7FBF6 68%, #D7FBF6 100%)",
        backgroundBlendMode: "overlay, overlay, overlay, darken, normal",
      }}
    >
      <PageContainer>
        <Container maxW={"7xl"} mt={10}>
          <Container
            data-testid="test"
            maxW={"7xl"}
            px={0}
            py={{ base: 10, sm: 20, lg: 32 }}
          >
            <Stack spacing={{ base: 10, md: 20 }}>
              <Heading
                color={"white"}
                lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
              >
                <Text>{firstLineText}</Text>
                <Text>{secondLineText}</Text>
              </Heading>
              <Stack direction={"row"} spacing={4} align={"center"}>
                <Button
                  backgroundColor={"highlightColor"}
                  color="white"
                  onClick={() => router.replace("/")}
                >
                  Zurück zur Startseite
                </Button>
              </Stack>
            </Stack>
          </Container>
        </Container>
      </PageContainer>
    </Box>
  );
};
