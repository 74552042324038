import { Center, Box, Link as ChakraLink } from "@chakra-ui/react";
import React from "react";
import { FaLinkedin, FaXing } from "react-icons/fa";

export const socialUrls = {
  linkedIn: "https://linkedin.com/in/",
  xing: "https://xing.com/profile/",
};

interface ISocialButton {
  linkedIn: string | null;
  xing: string | null;
  size: "small" | "medium";
}

export const SocialButton: React.FC<ISocialButton> = ({
  linkedIn,
  xing,
  size,
}) => {
  const iconSize = {
    small: "15px",
    medium: "30px",
  }[size];

  return (
    <Center mt={size === "small" ? 2 : 6}>
      {linkedIn ? (
        <ChakraLink href={`${socialUrls["linkedIn"]}${linkedIn}`}>
          <Box>
            <FaLinkedin size={iconSize} cursor={"pointer"} />
          </Box>
        </ChakraLink>
      ) : null}
      {xing ? (
        <ChakraLink href={`${socialUrls["xing"]}${xing}`}>
          <Box px={2}>
            <FaXing size={iconSize} cursor={"pointer"} />
          </Box>
        </ChakraLink>
      ) : null}
    </Center>
  );
};
