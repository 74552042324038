import React from "react";
import {
  Box,
  Flex,
  Center,
  Heading,
  Text,
  Stack,
  Tag,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { FaRegCalendarAlt } from "react-icons/fa";
import Link from "next/link";
import { IMentorModel } from "../types/mentorModel";
import { MentorInformation } from "..";

interface IWorkshopCard {
  id: string;
  title: string;
  online: boolean;
  mentor: IMentorModel;
  startDate?: string | null;
  startTime?: string | null;
  endDate?: string | null;
  heroGradientStyles: Record<string, unknown>;
  rating?: number;
  tags?: string[];
}

export const Workshop: React.FC<IWorkshopCard> = ({
  id,
  title,
  mentor,
  startDate,
  startTime,
  endDate,
  online,
  heroGradientStyles,
}) => {
  return (
    <Center py={6} mr={5}>
      <Box
        maxW={"300px"}
        h={"320px"}
        w={"300px"}
        bg={"white"}
        boxShadow={"2xl"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
        pos="relative"
      >
        <Flex
          h={"80px"}
          mt={-6}
          mx={-6}
          mb={6}
          pos={"relative"}
          style={{ ...heroGradientStyles }}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {online && (
            <Tag
              size="md"
              colorScheme="blue"
              mt={3}
              mr={3}
              _before={{
                content: `""`,
                w: "10px",
                h: "10px",
                borderRadius: "50%",
                backgroundColor: "highlightColor",
                mr: "5px ",
              }}
            >
              online
            </Tag>
          )}
        </Flex>

        <Stack>
          <Flex>
            <FaRegCalendarAlt height={5} width={5} />
            {startDate && endDate ? (
              <Text fontSize={"sm"} ml={2} fontWeight={"semibold"}>
                {startDate === endDate && startTime
                  ? `${startDate} ${startTime}`
                  : startDate === endDate && !startTime
                  ? startDate
                  : `${startDate} - ${endDate}`}
              </Text>
            ) : (
              <Text fontSize={"sm"} ml={2} fontWeight={"semibold"}>
                auf Anfrage
              </Text>
            )}
          </Flex>

          <Link href={`/workshops/${id}`}>
            <ChakraLink>
              <Heading
                style={{
                  hyphens: "auto",
                }}
                fontSize={"2xl"}
                fontFamily={"body"}
                noOfLines={3}
              >
                {title}
              </Heading>
            </ChakraLink>
          </Link>
        </Stack>
        <Box pos="absolute" bottom="0" pb={6}>
          <MentorInformation
            id={mentor.id}
            firstName={mentor.firstName}
            lastName={mentor.lastName}
            company={mentor.company}
            jobTitle={mentor.jobTitle}
            imageUrl={mentor.imageUrl}
          />
        </Box>
      </Box>
    </Center>
  );
};
