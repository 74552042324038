import { Option } from "..";

export interface ILanguageModel {
  name: string;
}

export const DB_LANGUAGES = [
  "Englisch",
  "Deutsch",
  "Chinesisch",
  "Spanisch",
  "Französisch",
  "Arabisch",
  "Hindi",
  "Russisch",
  "Portugiesisch",
  "Bengalisch",
  "Japanisch	",
  "Koreanisch",
] as const;

export type DB_LANGUAGES = typeof DB_LANGUAGES;

export const mapDBLanguagesToOptions = (
  dbCategories: DB_LANGUAGES | DB_LANGUAGES[number][]
) => {
  return dbCategories.map((cat) => mapDBLanguageToOption(cat));
};

export const mapDBLanguageToOption = (cat: typeof DB_LANGUAGES[number]) => ({
  label: cat,
  value: cat,
});

export const mapLanguageOptionsToDbCategories = (options: Option[]) => {
  return options.map((option) => option.value as DB_LANGUAGES[number]);
};
