import { ICategoryModel } from "../categoryModel";
import { IHashtagModel } from "../hashtagModel";

export interface IDbWorkshopModel {
  id: string;
  active: boolean;
  title: string;
  shortDescription: string;
  category: ICategoryModel[];
  hashtags: IHashtagModel[];
  mentorId: string;

  languages: { name: string }[];
  price: Price;

  purpose: string;
  goal: string;
  process: string;
  targetGroup: string;

  location: Location;
  dateTime: DateTime;
  // optional
  participants?: Participants;
  keyInformation?: string[] | null;
  testimonials?: string[];
  rating?: number;
  reviews?: string[];
  batch?: Batch;
}

export type Price = {
  amount: string;
  type: "total" | "perParticipant";
};

export type Participants = {
  min: number;
  max: number;
};

export type Location = {
  type: LocationEnum[];
  comment: string | null;
};

export enum LocationEnum {
  ONLINE = "online",
  IN_HOUSE = "inHouse",
}

export enum DurationEnum {
  TWO_HOUR = "2std",
  FOUR_HOUR = "4std",
  EIGHT_HOUR = "8std",
  CUSTOM = "custom",
}
type Duration = DurationEnum;
type Batch = {
  type: string;
};

export type DateTime = {
  type: WorkshopTypeEnum;
  startDate?: string | null;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  duration: number;
};

export enum WorkshopTypeEnum {
  SCHEDULED = "scheduled",
  FREE = "free",
}
